// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Button.tsx");
  import.meta.hot.lastModified = "1724675030523.153";
}
// REMIX HMR END

import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { Loader2 } from 'lucide-react';
import * as React from 'react';
import { forwardRef } from 'react';
import { cn } from '~/utils/cn';
import { useAdornments } from './Adornment';
export const buttonVariants = cva(`inline-flex items-center justify-center font-medium transition
  focus-visible:outline-none focus-visible:ring-2 ease-in-out
  focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 cursor-pointer
  disabled:cursor-not-allowed ring-offset-background select-none rounded text-sm whitespace-nowrap`, {
  variants: {
    variant: {
      'primary': 'bg-primary text-slate-50 hover:bg-primary/90 disabled:hover:bg-primary',
      'error': 'bg-destructive text-destructive-foreground hover:bg-destructive/90 disabled:hover:bg-destructive',
      'outline': 'border hover:bg-button-hover hover:text-accent-foreground disabled:hover:text-foreground',
      'outline-error': 'border border-destructive text-destructive hover:bg-button-hover',
      'secondary': 'border bg-secondary disabled:hover:bg-secondary disabled:hover:text-foreground hover:bg-secondary/80 hover:text-accent-foreground rounded-md',
      'ghost': 'bg-transparent text-primary hover:bg-button-hover disabled:hover:bg-transparent',
      'link': 'underline-offset-4 disabled:hover:no-underline hover:underline text-primary',
      'ns-outline': 'border hover:text-indigo-500 text-indigo-600 hover:bg-indigo-100 disabled:hover:bg-transparent border-indigo-500',
      'ns-primary': 'text-slate-50 bg-indigo-600 disabled:hover:bg-indigo-600 hover:bg-indigo-500',
      'ns-ghost': 'font-semibold text-indigo-600 hover:text-indigo-500 disabled:hover:bg-transparent hover:bg-indigo-100',
      'card': `border border-primary bg-white duration-150
          shadow-sm hover:border-hover font-normal hover:shadow-md`
    },
    size: {
      'xs': 'py-1 px-2 rounded-sm text-xs h-7',
      // TODO: figure out correct sizes for each variant.
      // I tweaked medium so that it also works with adornments
      'sm': 'py-1 px-2 h-7',
      'md': 'py-2 px-2.5',
      // ✅ lg inline with Figma
      'lg': 'h-9 px-3',
      // TODO: convert these variants to the values below based on our Figma designs
      // https://www.figma.com/file/Q6ujJJHb2c2HB7JPM1Zq9x/V11-Design-Planning?type=design&node-id=2544-110870
      // 'sm': 'h-4 px-1 py-0.5',
      // 'md': 'h-7 px-2 py-1',
      'icon-lg': 'h-8 w-8 rounded [&>svg]:box-border [&>svg]:w-4 [&>svg]:h-4',
      // TODO: add svg selection like icon-sm
      'icon': 'h-7 w-7 rounded [&>svg]:box-border [&>svg]:w-4 [&>svg]:h-4',
      'icon-sm': 'h-6 w-6 rounded-[2px] [&>svg]:box-border [&>svg]:w-3 [&>svg]:h-3'
    }
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md'
  }
});
export const Button = _s(forwardRef(_c = _s(({
  children,
  className,
  disabled,
  leftAdornment,
  rightAdornment,
  type = 'button',
  size = 'md',
  asChild = false,
  isLoading = false,
  variant = 'primary',
  loadingIcon = true,
  loadingIconPlacement = 'right',
  ...props
}, ref) => {
  _s();
  const Comp = asChild ? Slot : 'button';
  const {
    left,
    right,
    classNames: {
      adornedParentClass,
      adornmentPaddingClass
    }
  } = useAdornments({
    leftAdornment: isLoading && loadingIcon && loadingIconPlacement === 'left' ? <ButtonLoadingIcon /> : leftAdornment,
    rightAdornment: isLoading && loadingIcon && loadingIconPlacement === 'right' ? <ButtonLoadingIcon /> : rightAdornment,
    size
  });
  return <Comp {...asChild ? {} : {
    type
  }} disabled={disabled ?? isLoading} className={cn(buttonVariants({
    variant,
    size
  }), isLoading && 'disabled:cursor-wait', adornedParentClass, adornmentPaddingClass, className)} ref={ref} {...props}>
        {asChild ? children : <>
            {left}
            {size === 'icon' && isLoading ? <ButtonLoadingIcon /> : children}
            {right}
          </>}
      </Comp>;
}, "VK/6QBhKm8RgYFfcJBC/QTWD2+A=", false, function () {
  return [useAdornments];
})), "VK/6QBhKm8RgYFfcJBC/QTWD2+A=", false, function () {
  return [useAdornments];
});
_c2 = Button;
Button.displayName = 'Button';
function ButtonLoadingIcon({
  className = 'animate-spin',
  ...props
}) {
  return <Loader2 className={className} {...props} />;
}
_c3 = ButtonLoadingIcon;
var _c, _c2, _c3;
$RefreshReg$(_c, "Button$forwardRef");
$RefreshReg$(_c2, "Button");
$RefreshReg$(_c3, "ButtonLoadingIcon");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;